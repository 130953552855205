import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IAppDetails } from '../../shared/misc/interfaces/app-details.interface';
import { IJbdUser } from '../../shared/misc/interfaces/user.interface';
import { JbdCoreAuthService } from '../auth/auth.service';
import { JbdCoreDataService } from '../data/data.service';
import { JbdCoreRedirectService } from '../redirect/redirect.service';

@Injectable()
export abstract class JbdCoreUserService {
  protected constructor(
    @Inject('environment') protected environment: { appDetails: IAppDetails },
    protected authService: JbdCoreAuthService,
    protected coreDataService: JbdCoreDataService
  ) {}

  public getUser(): IJbdUser | null {
    const userString = localStorage?.getItem('user') ?? '';

    if (userString) {
      return JSON.parse(decodeURIComponent(escape(atob(userString))));
    }

    return null;
  }

  public isCurrentUser(id: string): boolean {
    return this.getUser()?.id === id;
  }

  public setUser(user: IJbdUser): void {
    localStorage.setItem(
      'user',
      btoa(unescape(encodeURIComponent(JSON.stringify(user))))
    );
  }

  public clearUser(): void {
    localStorage.removeItem('user');
  }

  public loadUser(): Observable<IJbdUser> {
    return this.coreDataService.getMe().pipe(tap((user) => this.setUser(user)));
  }

  public logoutUser(): Promise<string | undefined> {
    return new Promise((resolve) => {
      this.authService.clearToken();
      this.clearUser();
      JbdCoreRedirectService.clearRedirectUrl();
      resolve(this.environment.appDetails.urls?.login);
    });
  }

  public isLoggedIn(): boolean {
    return this.authService.hasValidToken();
  }

  public hasUserAttribute(key: string): boolean {
    return !!this.getUser()?._embedded?.attributes?.find(
      (attribute) => attribute.key === key
    );
  }

  public get userId(): string {
    return this.getUser()?.id ?? '';
  }

  public getUserRoute(): string | undefined {
    return;
  }
}
